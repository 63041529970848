<template>
  <div>
    <!-- 筛选条件 v-if="!isShowMore || pItem.grad === 0" -->
    <div class="nav-sort-box" :class="[alias + '-nav-sort-box']">
      <template v-for="item in filterList">
        <template v-if="item.type == 0">
          <el-select
            class="W100px filter-item"
            size="mini"
            clearable
            @change="onChange"
            v-model="item.fieldValue"
            :placeholder="item.name"
            :key="item.id"
            multiple
          >
            <el-option v-for="child in item.children" :value="child.id" :label="child.name" :key="child.id">
              <div class="filter-option-item">
                <img v-if="child.icon" :src="handleImgSize(child.icon, 'mini')" alt="" />
                <span :style="{ color: child.color }">{{ child.name }}</span>
              </div>
            </el-option>
          </el-select>
        </template>
        <template v-else>
          <el-button @click="onSort(item)" class="filter-item W100px" plain size="mini" :key="item.id">
            {{ item.name }}
            <i :class="`${sortKey == item.id && sortValue ? 'el-icon-caret' + sortValue : 'el-icon-d-caret'}`"></i>
          </el-button>
        </template>
      </template>
      <el-input
        prefix-icon="el-icon-search"
        size="mini"
        style="width: 210px;margin-bottom: 10px;"
        v-if="filterList.length > 0"
        type="text"
        v-model="searchKw"
        @input="onChange"
        placeholder="搜索词条"
      />
    </div>
    <!-- 内容列表 -->
    <div :class="['item-wrapper', `icon-size-${isMaxIcon || 1}`, 'item-group', alias + '-item-group']">
      <template v-for="child in calcList">
        <template v-if="isExternal">
          <div
            :key="child.id"
            :class="[child.content_id || child.jump_url ? '' : 'opacity']"
            :title="child.name"
            class="item"
            @click="onClickToExternal(child)"
          >
            <template v-if="showIcon(isMaxIcon, child.icon)">
              <img v-lazy="handleImgSize(child.icon || deaultImg, 'list')" :alt="gameInfo.name + child.name" />
            </template>
            <span :style="itemBg(child, isMaxIcon)">{{ child.name }}</span>
          </div>
        </template>
        <template v-else>
          <template v-if="child.content_id">
            <router-link
              :key="child.id"
              :class="[child.content_id || child.jump_url ? '' : 'opacity']"
              :title="child.name"
              :to="getJumpWikiDetailUrl(gameInfo.alias, gameInfo.id, child.content_id, true, child.type == 3)"
              class="item"
            >
              <i v-if="child.is_new" class="icon new"></i>
              <i v-else-if="child.is_update" class="icon update"></i>
              <template v-if="showIcon(isMaxIcon, child.icon)">
                <img v-lazy="handleImgSize(child.icon || deaultImg, 'list')" :alt="gameInfo.name + child.name" />
              </template>
              <span :style="itemBg(child, isMaxIcon)">{{ child.name }}</span>
            </router-link>
          </template>
          <template v-else-if="child.jump_url">
            <a
              :key="child.id"
              :class="[child.content_id || child.jump_url ? '' : 'opacity']"
              :href="child.jump_url"
              :title="child.name"
              class="item"
            >
              <i v-if="child.is_new" class="icon new"></i>
              <i v-else-if="child.is_update" class="icon update"></i>
              <template v-if="showIcon(isMaxIcon, child.icon)">
                <img v-lazy="handleImgSize(child.icon || deaultImg, 'list')" :alt="gameInfo.name + child.name" />
              </template>
              <span :style="itemBg(child, isMaxIcon)">{{ child.name }}</span>
            </a>
          </template>
          <template v-else>
            <div
              :key="child.id"
              :class="[child.content_id || child.jump_url ? '' : 'opacity']"
              :title="child.name"
              class="item"
              @click="handleToPage(child)"
            >
              <i v-if="child.is_new" class="icon new"></i>
              <i v-else-if="child.is_update" class="icon update"></i>
              <template v-if="showIcon(isMaxIcon, child.icon)">
                <img v-lazy="handleImgSize(child.icon || deaultImg, 'list')" :alt="gameInfo.name + child.name" />
              </template>
              <span :style="itemBg(child, isMaxIcon)">{{ child.name }}</span>
            </div>
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiEntryFilterGetEntryFilter } from "@/api/entryFilterApi";
import { getJumpWikiDetailUrl } from "@/plugins/util";

const iconWidthNumber = {
  2: 11,
  6: 8,
  7: 8,
  1: 5,
  3: 6,
  4: 5,
  5: 5
};

export default {
  name: "homeItemWrapper",
  mixins: [],
  props: {
    pItem: {
      type: Object,
      default: () => {}
    },
    isMaxIcon: {
      type: Number,
      default: 1
    },
    // 是否需要展示更多按钮
    isShowMore: {
      type: Boolean,
      default: false
    }
  },
  model: {},
  components: {},
  data() {
    return {
      deaultImg: require("@/assets/images/local.png"),
      isExternal: false,
      filterList: [],
      filterAttr: [],
      list: [],
      sortKey: "",
      sortValue: "",
      searchKw: ""
    };
  },
  computed: {
    ...mapGetters(["gameInfo"]),
    calcList() {
      let list = JSON.parse(JSON.stringify(this.list));
      if (!Array.isArray(list)) {
        list = [];
      }
      if (this.isShowMore) {
        const grad = this.pItem.grad;
        const curNumber = iconWidthNumber[this.isMaxIcon];
        if (grad) {
          const number = grad * curNumber;
          return list.slice(0, number);
        } else {
          return list;
        }
      } else {
        return list;
      }
    }
  },
  methods: {
    getJumpWikiDetailUrl,
    itemBg(item, isMaxIcon) {
      let style = {};
      // 仅中图标和大图标才能设置颜色
      if ([2, 3, 6, 7].includes(isMaxIcon)) {
        style.color = item.font_color;
        style.background = item.bg_color;
      }
      return style;
    },
    onClickToExternal(item) {
      if (item.content_id) {
        let url = window.location.origin + "/external/" + item.content_id + ".html";
        console.log(url);
        // eslint-disable-next-line
        YppBridge.call("pt_game_entry", {
          entryId: item.content_id,
          entryUrl: url
        });
      } else if (item.jump_url) {
        // eslint-disable-next-line
        YppBridge.call("pt_game_entry", {
          entryUrl: item.jump_url
        });
      } else {
        // eslint-disable-next-line
        YppBridge.call("ui_showToast", {
          message: "词条还在编辑中"
        });
      }
    },
    handleToPage(item) {
      let path = "";
      // 没有关联的文章，需要判断是否登录，是否有权限编辑
      if (!this.isLoginShow()) return false;
      if (!this.isAuthRealName()) return false;
      if (!this.$hasPermission("wiki_index_entry_edit")) {
        this.$message("词条还在编辑中");
        return;
      }
      if (item.type === 1) {
        // 文章编辑词条
        if (this.gameInfo.alias !== "www") {
          path = "/" + this.gameInfo.alias + "/editor?entry_id=" + item.id;
        } else {
          path = "/editor?entry_id=" + item.id;
        }
      } else if (item.type === 2) {
        // 外部链接
      } else if (item.type === 3) {
        // 新图鉴词条
        path = `/${this.gameInfo.alias}/rich-table?entry_id=${item.id}`;
      }
      path += "&title=" + item.name;
      this.$router.push(path);
    },
    showIcon(isMaxIcon = 1, icon) {
      if ([1, 4, 5].includes(isMaxIcon) && !icon) {
        return false;
      } else {
        return true;
      }
    },
    getEntryFilter() {
      if (this.pItem.is_filter != 1) return;
      apiEntryFilterGetEntryFilter({ entry_id: this.pItem.id }).then(res => {
        if (res.code == 0) {
          res.data.entry_filter.forEach(item => {
            item.fieldValue = "";
          });
          let list = [];
          Object.keys(res.data.entry_filter_attr).forEach(key => {
            res.data.entry_filter_attr[key].forEach(item => {
              if (Array.isArray(item.value)) {
                item.value = item.value.map(it => Number(it));
              } else if (!isNaN(Number(item.value))) {
                item.value = Number(item.value);
              } else {
                // 格式化后的时分秒转时间戳
                item.value = new Date(`2020-02-02 ${item.value}`).getTime();
              }
            });
            list.push({
              key: Number(key),
              data: res.data.entry_filter_attr[key]
            });
          });
          this.filterList = res.data.entry_filter;
          this.filterAttr = list;
        }
      });
    },
    onSort(item) {
      if (item.id != this.sortKey) {
        this.sortKey = item.id;
        this.sortValue = "-bottom";
      } else {
        if (this.sortValue == "-bottom") {
          this.sortValue = "-top";
        } else if (this.sortValue == "-top") {
          this.sortValue = "";
        } else {
          this.sortValue = "-bottom";
        }
      }
      this.onChange();
    },
    onChange() {
      let { child: list } = this.pItem;
      const { filterAttr } = this;
      let filters = [];
      // 需要展示的词条id
      let ids = [];
      // 先查出筛选项
      this.filterList.forEach(item => {
        if (item.type != 0) return;
        if (item.fieldValue && item.fieldValue.length > 0) {
          filters.push(item.fieldValue);
        }
      });

      if (filters.length == 0 && !this.sortValue && !this.searchKw) {
        this.list = list;
        return;
      }

      if (filters.length <= 0) {
        ids = [...filterAttr];
      } else {
        // 查找满足条件的词条
        const result = filterAttr.filter(item => {
          // 同一个条件取并，
          return filters.every(val => {
            let values = item.data.map(i => i.value).flat();
            return val.some(v => values.includes(v));
          });
        });
        ids = [...result];
      }

      // 排序
      if (this.sortValue) {
        ids = ids.sort((a, b) => {
          let aItem = a.data.filter(i => i.input_id == this.sortKey)[0];
          let bItem = b.data.filter(i => i.input_id == this.sortKey)[0];

          let a_val = (aItem && aItem.value) || 0;
          let b_val = (bItem && bItem.value) || 0;
          return this.sortValue == "-bottom" ? b_val - a_val : a_val - b_val;
        });
      }

      list = ids.map(i => {
        return list.filter(item => item.id == i.key)[0];
      });
      this.list = list.filter(
        item => !this.searchKw || item.name.includes(this.searchKw) || item.name_alias.includes(this.searchKw)
      );

      // this.list = list.filter(item => ids.map(i => i.key).includes(item.id));
    }
  },
  watch: {},
  created() {
    this.isExternal = this.$route.path.includes("external");
    this.list = this.pItem.child;
  },
  beforeMount() {
    this.getEntryFilter();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.item-wrapper {
  display: flex;
  flex-wrap: wrap;

  .item {
    display: flex;
    cursor: pointer;
    position: relative;

    .icon {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      display: block;
      width: 18px;
      height: 18px;

      &.new {
        background: url("~@/assets/images/img1.png");
        background-size: 100% 100%;
      }

      &.update {
        background: url("~@/assets/images/img2.png");
        background-size: 100% 100%;
      }
    }

    &.opacity {
      opacity: 0.5;
    }
  }

  &.icon-size-1,
  &.icon-size-4,
  &.icon-size-5 {
    .item {
      height: 30px;
      overflow: hidden;
      background: #f2f2f2;
      border-radius: 2px;
      display: flex;
      margin-right: 12px;
      margin-bottom: 8px;
      align-items: center;
      transition: 0.3s;
      border: 1px solid #dfdfdf;
      transition: all 0.3s;
      -webkit-font-smoothing: antialiased;
      &:hover {
        transform: translateY(-2px) translateZ(0) scale(1.1);
      }

      img {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        margin-left: 4px;
        object-fit: cover;
      }

      span {
        .ellipsis;
        width: 100%;
        display: block;
        padding: 0 4px 0 8px;
        color: #424242;
        font-size: 14px;
        text-align: center;
      }

      img + span {
        width: clac(100% - 26px);
        text-align: left;
      }
    }
  }

  &.icon-size-2,
  &.icon-size-3 {
    .item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      background: #f8f8f8;
      border-radius: 2px;
      overflow: hidden;
      border: 1px solid #e5e5e5;
      transition: all 0.3s;
      -webkit-font-smoothing: antialiased;
      &:hover {
        transform: translateY(-2px) translateZ(0) scale(1.1);
      }

      img {
        width: 100%;
        height: 70px;
        max-width: 100%;
        object-fit: contain;
      }

      span {
        .ellipsis;
        line-height: 20px;
        height: 20px;
        color: #424242;
        width: 100%;
        font-size: 12px;
        text-align: center;
      }
    }
  }

  &.icon-size-6,
  &.icon-size-7 {
    .item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      background: #f8f8f8;
      border: 1px solid #e5e5e5;
      width: calc((100% - 8px * 7) / 8);
      margin-right: 8px;
      position: relative;
      border-radius: 2px;
      overflow: hidden;
      transition: all 0.3s;
      -webkit-font-smoothing: antialiased;
      &:hover {
        transform: translateY(-2px) translateZ(0) scale(1.1);
      }

      &:nth-child(8n) {
        margin-right: 0;
      }

      img {
        width: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      span {
        .ellipsis;
        line-height: 20px;
        height: 20px;
        color: #424242;
        width: 100%;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}

.item-group {
  &.icon-size-1,
  &.icon-size-4,
  &.icon-size-5 {
    .item {
      width: calc((100% - 12px * 4) / 5);

      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }

  &.icon-size-2 {
    .item {
      width: calc((100% - 10px * 7.5) / 11);
      margin-right: 7px;

      &:nth-child(11n) {
        margin-right: 0;
      }
    }
  }

  &.icon-size-3 {
    .item {
      width: calc((100% - 13px * 5) / 6);
      margin-right: 13px;

      &:nth-child(6n) {
        margin-right: 0;
      }
    }
  }

  &.icon-size-6 {
    .item {
      img {
        height: 90px;
      }
    }
  }

  &.icon-size-7 {
    .item {
      img {
        height: 117px;
      }
    }
  }
}

.nav-sort-box {
  .filter-item {
    min-height: 28px;
    margin-right: 10px;
    margin-bottom: 10px;

    & + .filter-item {
      margin-left: 0;
    }
  }
}
.filter-option-item {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

.ba-item-group {
  &.icon-size-3 {
    .item {
      height: auto;
      background: #f7feff;
      box-shadow: 0px 2px 4px 0px rgba(85, 85, 85, 0.5);
      border-radius: 4px;
      padding: 3px 3px 0 3px;
      img {
        width: 100%;
        height: 67px;
        border-radius: 4px;
      }
      span {
        line-height: 26px;
        height: 26px;
        color: #2c343f;
      }
    }
  }
  &.icon-size-6 {
    .item {
      background: url("~@/assets/ba_imgs/item-bg-icon.png") no-repeat center / 100% 100%;
      height: 140px;
      padding: 8px 12px 14px 12px;
      border: none;
      border-radius: 2px;
      img {
        border-radius: 4px;
        border: 2px solid #fff;
        box-shadow: 0 1px 1px #999;
        height: 85px;
        background: linear-gradient(180deg, #dadee6 0%, #ffffff 100%);
      }
      span {
        background: #565d7a;
        color: #fff;
        font-size: 12px;
        margin-top: 8px;
        border-radius: 4px;
        padding: 0 4px;
        text-align: center;
      }
    }
  }
  &.icon-size-2 {
    .item {
      background: url("~@/assets/ba_imgs/item-bg-icon.png") no-repeat center / 100% 100%;
      height: 105px;
      padding: 5px 6px 10px 6px;
      border: none;
      border-radius: 2px;
      justify-content: space-between;
      img {
        border-radius: 4px;
        border: 2px solid #fff;
        box-shadow: 0 1px 1px #999;
        height: 60px;
        background: linear-gradient(180deg, #dadee6 0%, #ffffff 100%);
      }
      span {
        background: #565d7a;
        color: #fff;
        font-size: 12px;
        margin-top: 8px;
        border-radius: 4px;
        padding: 0 4px;
        text-align: center;
      }
    }
  }
  &.icon-size-1 {
    .item {
      background: url("~@/assets/ba_imgs/item-bg-bar.png") no-repeat center / 100% 100%;
      border: none;
      font-size: 13px;
      color: #424242;
      padding: 0 8px;
    }
  }
}

.zsca2-nav-sort-box {
  /deep/ .el-input {
    input {
      border: 1px solid rgba(216, 216, 216, 0.16);
      background: rgba(216, 216, 216, 0.16);
    }
  }
  /deep/ .el-button {
    border: 1px solid rgba(216, 216, 216, 0.16);
    background: rgba(216, 216, 216, 0.16);
  }
}
.zsca2-item-group {
  &.icon-size-1,
  &.icon-size-4,
  &.icon-size-5 {
    .item {
      height: 28px;
      border: 1px solid transparent;
      background: var(--zs-model-wrapper-item-bg);
      border-radius: 4px;
      span {
        color: var(--zs-model-wrapper-item-name-color);
      }
    }
  }
  &.icon-size-2,
  &.icon-size-3 {
    .item {
      border: 1px solid transparent;
      background: var(--zs-model-wrapper-item-bg);
      border-radius: 8px;
      span {
        color: var(--zs-model-wrapper-item-name-color);
      }
    }
  }
  &.icon-size-6 {
    .item {
      border: 1px solid transparent;
      background: var(--zs-model-wrapper-item-bg);
      border-radius: 8px;
      span {
        color: var(--zs-model-wrapper-item-name-color);
      }
      img {
        height: 92px;
      }
    }
  }
  &.icon-size-7 {
    .item {
      border: 1px solid transparent;
      background: var(--zs-model-wrapper-item-bg);
      border-radius: 8px;
      span {
        color: var(--zs-model-wrapper-item-name-color);
      }
      img {
        height: 120px;
      }
    }
  }
}
</style>
